import { connect } from 'react-redux' 
import Incident from '../components/incidents/Incident'
import { editIncidentLoad, editIncidentClear, editIncidentUpdate, editIncidentClearError, 
    editIncidentSave,editIncidentCheckReportingPeriodDate, editIncidentSetStep, editIncidentUploadRequest,
    editIncidentPatronUploadRequest, editIncidentChecklistItemUploadRequest, editIncidentChecklistsRequest
 } from '../common/actions/editIncident'
import { withRouter } from "react-router";
import { locationRequest } from '../common/actions/location';
import { notificationShow } from '../common/actions/notification';
import { incidentLogic } from '../common/logic/incidentLogic';

const mapStateToProps = (state, props)=> {
    return {
        incidentId: props.match.params.incidentid,
        incident: state.editIncident.incident,
        incidentOptions: state.editIncident.incidentOptions,
        isLoading: state.editIncident.isLoading,
        isSaving: state.editIncident.isSaving,
        error: state.editIncident.error,
        isLocationLoading: state.location.isLoading,
        latitude: state.location.latitude,
        longitude: state.location.longitude,
        locationFound: state.location.found,
        locationError: state.location.error,
        step: state.editIncident.step,
        isUploading: state.editIncident.isUploading,
        hasChecklists: state.editIncident.hasChecklists,
        isLoadingChecklists: state.editIncident.isLoadingChecklists,
        isCommsLog: incidentLogic.incidentTypeEntryPage(state.editIncident.incident, state.editIncident.incidentOptions) === "CommsLog"
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadIncident: (incidentId) => {
            dispatch(editIncidentLoad(incidentId))
        },
        onClear: () => {
            dispatch(editIncidentClear())
        },
        onUpdateIncident: (incident) => {
            dispatch(editIncidentUpdate(incident))
        },
        onClearError: () => {
            dispatch(editIncidentClearError())
        },
        onSaveDraft: () => {
            dispatch(editIncidentSave(true, false))
        },
        onSaveFinal: () => {
            dispatch(editIncidentSave(false, false))
        },
        onSaveFinalAndApprove: () => {
            dispatch(editIncidentSave(false, true))
        },
        onCheckIncidentDateReportingPeriod: (incidentDate, venueEventId) => {
            dispatch(editIncidentCheckReportingPeriodDate(incidentDate, venueEventId))
        },
        onRefreshLocation: () => {
            dispatch(locationRequest())
        },
        onErrorNotification: (message) => {
            dispatch(notificationShow(message));
        },
        onSetStep: (step) => {
            dispatch(editIncidentSetStep(step));
        },
        onUploadFiles:(files) => {
            dispatch(editIncidentUploadRequest(files))
        },
        onUploadPatronFiles: (patronIndex, files) => {
            dispatch(editIncidentPatronUploadRequest(patronIndex ,files))
        },
        onUploadAttachment: (files, checklistTemplateItemId) => {
            dispatch(editIncidentChecklistItemUploadRequest(files, checklistTemplateItemId))
        },
        onLoadChecklists: () => {
            dispatch(editIncidentChecklistsRequest())
        }
    }
}

const IncidentContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Incident))

export default IncidentContainer